// MAIN.JS
jQuery(function($) {

	/**********************************************/
	// JQUERY.APPEAR
	if ($.fn.appear) {
		var appearSelectors = ".appear";
		$(appearSelectors).on('appear', function(event, $all_appeared_elements) {
			$.each($all_appeared_elements, function(e) {
				$(this).css("opacity", 1);
			});
		});
		$(appearSelectors).on('disappear', function(event, $all_disappeared_elements) {
			$.each($all_disappeared_elements, function(e) {
				// Activer ceci pour faire l'inverse lors que l'element sort de la partie visible
				// $(this).css("opacity", 0);
			});
		});
		$(window).load(function() {
			$(appearSelectors).appear({"force_process":true});
		});
	}

	/**********************************************/

	// JQUERY.MASKEDINPUT
    if ($.fn.mask) {
        $("input[type='tel']").mask("(999) 999-9999? #99999");
        $("input[type='text'].postalcode").mask("a9a 9a9", {
            completed:function () {
                this.val(this.val().toUpperCase());
            }
        });
    }

	/**********************************************/

/**********************************************/

	$slideshow = $('.slideshow');
	var initSlideshow = function() {
		return setInterval(function() {
			$slides = $slideshow.find('.slide');
			$active = $slideshow.find('.slide.active');
			$next = $active.next();

			if ($next.length > 0) {
				$active.removeClass('active');
				$active.addClass('transiting');
				$active.next().addClass('active');
			}
			else {
				$active.removeClass('active');
				$active.addClass('transiting');
				$slides.first().addClass('active');
			}
			setTimeout(function() {
				$active.removeClass('transiting');
			}, 1500);
		}, 3000);
	}

	$(document).ready(function() {
		var slideshowTimer = initSlideshow();
	});


	/**********************************************/
	/**********************************************/

	// MENU
	var menu = ".menu-mobile";
	var menuToggle = ".menu-toggle";
	$(menuToggle).click(function(e) {
		$(menu).toggleClass("toggled");
		$(menuToggle).toggleClass("toggled");
	});

	/**********************************************/

	// JQUERY.MATCHHEIGHT
	if ($.fn.matchHeight) {
		$(".matchHeight").matchHeight();
	}


	/************************************************************************/
	/* menu hide and show when scrolled	and other scroll functions			*/
	/************************************************************************/
    let didScroll;
    let lastScrollTop = 0;
    const delta = 10;
    let navbarHeight = $(".menu-navbar").outerHeight();
    // function to store scroll amount in <html data-scroll="">
	const storeScroll = () => {
        document.documentElement.dataset.scroll = window.scrollY;
    }

    // Set flag to see if window has scrolled
	$(window).scroll( function(event) {
		didScroll = true;
        storeScroll();
	});

    // Calls all scroll dependent scripts
    // And cut on processing time by the browser
    // by reading stats only x milliseconds (250)
	setInterval(function() {
		if (didScroll) {
            menuHasScrolled();
            toggleVisibleClassOnScroll();
            didScroll = false;
		}
	}, 250);

	// if element has "visibilityCheck" it will have "isVisible" class when
    // visible
	function toggleVisibleClassOnScroll() {
        $('.visibilityCheck').is( function () {
            let viewing = $(this).visible(true);
            if (viewing) {
                $(this).addClass('isVisible');
            } else {
                $(this).removeClass('isVisible');
            }
        });
        $('.completeVisibilityCheck').is( function () {
            let viewing = $(this).visible();
            if (viewing) {
                $(this).addClass('isCompletelyVisible');
            } else {
                $(this).removeClass('isCompletelyVisible');
            }
        });
    }

    // Adds the nav-invisible and nav-visible to element with class menu-desktop
	function menuHasScrolled() {
        let st = parseInt(document.documentElement.dataset.scroll);

        // Make sure they scroll more than delta
	    if ( Math.abs(lastScrollTop - st) <= delta )
	        return;
	    // If they scrolled down and are past the navbar, add class .nav-invisible.
	    // This is necessary so you never see what is "behind" the navbar.
        //console.log('st: ' + st);
        //console.log('lastScrollTop: ' + lastScrollTop);

        if ( st < parseInt(lastScrollTop) ) {
            // Scroll Up
            $('.menu-navbar').addClass('nav-visible').removeClass('nav-invisible');
        }
	    if (st > lastScrollTop && st > navbarHeight) {
	        // Scroll Down
	        $('.menu-navbar').removeClass('nav-visible').addClass('nav-invisible');
	    }

		lastScrollTop = st;

		if (st > 0) {
			$('.module-menu').addClass('scrolled');
		}
		else {
			$('.module-menu').removeClass('scrolled');
		}
	}

	// write the scroll position on page load
    $(document).ready(function () {
        storeScroll();
    });


	/************************************************/
	/* Ferme le popup si cliquer	 				*/
	/************************************************/
	$(".popup").click(function(e) {
	    $(".popup").fadeOut();
	});



			$(".btn-plus").each(function( index ) {
				$(this).on("click", function(){
					var id = $(this).parents('.content-small').attr('id');
					//alert(id);
					$(".full_text_" + id ).toggle(800);
					$(".small_text_" + id ).hide(800);
				});
			});

			$(".btn-moins").each(function( index ) {
				$(this).on("click", function(){
					var id = $(this).parents('.content-full').attr('id');
					$(".full_text_"  + id).hide(800);
					$(".small_text_"  + id).toggle(800);
				});
			});

	/************************************************/
	/* Montre les messages si présents 				*/
	/************************************************/
	$(document).ready(function () {
        if($('.errors').length) {
            $('html, body').animate({
                scrollTop: $('.errors').offset().top + 200
            }, 'slow');
        }
    });




		if($('.messages').length) {
			$('html, body').animate({
				scrollTop: $('.messages').offset().top + 200
			}, 'slow');
		}


		$('.owl-accomplissements').owlCarousel({
			//stagePadding: 50,/*the little visible images at the end of the carousel*/
			loop:true,
			rtl: false,
			lazyLoad:true,
			autoplay: true,
			margin:10,
			nav:false,
			navText: false,
			responsive:{
					0:{
							items:2
					},
					600:{
							items:2
					},
					800:{
							items: 3
					},
					1000:{
							items:2
					},
				1200:{
						items: 3
				}
			}
	});


/*carousel double*/


			var o2 = $('#sync2')
			o2.owlCarousel({
			  items: 1,
			  singleItem: true,
			  loop: false,
			  margin: 10,
			  dots: false,
			  pagination: false,
			  nav: false,

				fluidSpeed: 3000,
			  touchDrag: true,
			  slideBy: 1,
			  mouseDrag: false
			});

			var o1 = $('#sync1');
			o1.owlCarousel({
				items: 1,
				autoplay:true,
			  singleItem: true,
			  loop: false,
			  margin: 0,
			  //dots:false,
				autoplayTimeout:10000,
			  pagination: false,
			  nav: false,
			  touchDrag: true,
			  slideBy: 1,
			  mouseDrag: true
			});

			var o1 = $('#sync1'),
			  o2 = $('#sync2');

			// sync o2 on o1
			o1.on('changed.owl.carousel', function(event) {
			  let ind = event.item.index; //== 0 ? 0 : 2;
			  o2.trigger('to.owl.carousel', [ind]);
			});

			//numerizing dots
			var divs = $(".owl-dot").toArray();
			$.each(divs, function (index, value) {
					//var	numb = index.toFixed(2);
			    $(this).find("span").append((index+1));
			});

			/*carousel double*/


			$('#btnPause').on('click', function(e) {
			    $('#sync1').trigger('stop.owl.autoplay');
					$(this).addClass('active');
					$('#btnPlay').removeClass('active');
			})
			$('#btnPlay').on('click', function(e) {
			    $('#sync1').trigger('play.owl.autoplay');
					$(this).addClass('active');
					$('#btnPause').removeClass('active');
			})


    $('.caption').addClass ('show');
});
